import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { getTransactionsData } from "selectors/transactions";
import { useSelector } from "react-redux";
import CommentsBlock from "nlib/common/CommentsBlock";
import DataConstants from "const/DataConstants";
import Modal from "nlib/ui/Modal";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import Utils from "utils/Utils";
import useEnvVars from "hooks/useEnvVars";

const SCROLL_TO_INPUT_DEALY = 200;

const { COMMENT_TARGET_TYPES: { TRANSACTIONS } } = DataConstants;

const Comments = () => {
  const timeoutRef = useRef();

  const [{ transactionComments: transactionId }, setEnvVars] = useEnvVars();

  const { uiTexts } = useSelector(getTextsData);

  const transactionsData = useSelector(getTransactionsData);

  const transactionData = useMemo(() => {
    return Utils.arrayFindById(transactionsData, transactionId);
  }, [transactionId, transactionsData]);

  const handleModalClose = useCallback(() => {
    setEnvVars({ transactionComments: null });
  }, [setEnvVars]);

  const handleInputFocus = useCallback(({ target }) => {
    if (!target.scrollIntoView) return;

    timeoutRef.current = setTimeout(() => {
      target.scrollIntoView();
    }, SCROLL_TO_INPUT_DEALY);
  }, []);

  useEffect(() => {
    if (transactionId && transactionsData.length && !transactionData) {
      setEnvVars({ transactionComments: null });
    }
  }, [transactionId, transactionsData, transactionData, setEnvVars]);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  if (!transactionData) return null;

  const { comments } = transactionData;

  return (
    <Modal
      title={uiTexts.comments}
      className={Css.comments}
      dialogClassName={Css.dialog}
      contentClassName={Css.content}
      iconComponent={Icons.ChatText}
      onClose={handleModalClose}>
      <CommentsBlock
        commentTarget={TRANSACTIONS}
        commentTargetId={transactionId}
        comments={comments}
        onInputFocus={handleInputFocus} />
    </Modal>
  );
};

export default React.memo(Comments);
