import Css from "./style.module.scss";

import { checkIsBusinessUser } from "selectors/user";
import { getActiveOrganization } from "selectors/organizations";
import {
  getSelectedBusinessClasses,
  getSelectedBusinessData,
  getSelectedBusinessLocations,
  getSelectedBusinessProjects,
  getSelectedBusinessTaxRates
} from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Countries from "const/Countries";
import DataConstants from "const/DataConstants";
import FormLabel from "nlib/common/FormLabel";
import React, { useCallback } from "react";
import SelectClassInput from "nlib/common/SelectClassInput";
import SelectLocationInput from "nlib/common/SelectLocationInput";
import SelectProjectInput from "nlib/common/SelectProjectInput";
import SelectTaxRateInput from "nlib/common/SelectTaxRateInput";
import classNames from "classnames";

const {
  STATUSES: { TO_REPORT, EXPORTED }
} = DataConstants;

const ExtraDataInputs = (props) => {
  const {
    readOnly,
    disabled,
    className,
    documentPreviewMode,
    transactionState = {},
    transaction = {},
    onChange
  } = props;

  const { class: classValue = {}, location = {}, project = {}, taxRate = {} } = transactionState;

  const { id: classId } = classValue;

  const { id: locationId, name: locationName } = location;

  const { id: projectId, name: projectName } = project;

  const { status } = transaction;

  const { uiTexts } = useSelector(getTextsData);

  const { countryCode } = useSelector(getActiveOrganization);

  const usCountry = countryCode === Countries.US;

  const taxRates = useSelector(getSelectedBusinessTaxRates);

  const {
    settings: {
      allowClientClassSelection,
      allowClientLocationSelection,
      allowClientProjectSelection,
      allowClientTaxRateSelection
    } = {}
  } = useSelector(getSelectedBusinessData);

  const classes = useSelector(getSelectedBusinessClasses);

  const locations = useSelector(getSelectedBusinessLocations);

  const projects = useSelector(getSelectedBusinessProjects);

  const businessUser = useSelector(checkIsBusinessUser);

  const disabledInput = disabled || status === TO_REPORT || status === EXPORTED;

  const handleClassChange = useCallback((newValue) => {
    onChange({ class: newValue });
  }, [onChange]);

  const handleLocationChange = useCallback((newValue) => {
    onChange({ location: newValue });
  }, [onChange]);

  const handleProjectChange = useCallback((newValue) => {
    onChange({ project: newValue });
  }, [onChange]);

  const showClassesInput = (!businessUser || allowClientClassSelection) && !!classes.length;

  const handleTaxRateChange = useCallback((newValue) => {
    onChange({ taxRate: newValue });
  }, [onChange]);

  const showLocationsInput = (!businessUser || allowClientLocationSelection) && !!locations.length;

  const showProjectsInput = (!businessUser || allowClientProjectSelection) && !!projects.length;

  const showTaxRateInput = !usCountry && (!businessUser || allowClientTaxRateSelection) && !!taxRates.length;

  const showExtraData = showClassesInput || showLocationsInput || showProjectsInput || showTaxRateInput;

  if (!showExtraData) return null;

  return (
    <div className={classNames(Css.extraDataInputs, documentPreviewMode && Css.documentPreviewMode, className)}>
      {showClassesInput && (
        <div className={Css.input}>
          <FormLabel>{uiTexts.class}</FormLabel>
          <SelectClassInput
            disabled={disabledInput}
            readOnly={readOnly}
            invalid={!!(classValue.name && !classId)}
            valid={!!(classValue.name && classId)}
            value={classValue}
            onChange={handleClassChange} />
        </div>
      )}
      {showLocationsInput && (
        <div className={Css.input}>
          <FormLabel>{uiTexts.location}</FormLabel>
          <SelectLocationInput
            disabled={disabledInput}
            readOnly={readOnly}
            invalid={!!(locationName && !locationId)}
            valid={!!(locationName && locationId)}
            value={location}
            onChange={handleLocationChange} />
        </div>
      )}
      {showProjectsInput && (
        <div className={Css.input}>
          <FormLabel>
            {[
              projects.some(({ customer }) => !customer) && uiTexts.project,
              projects.some(({ customer }) => customer) && uiTexts.customer
            ].filter(Boolean).join(" / ")}
          </FormLabel>
          <SelectProjectInput
            disabled={disabledInput}
            readOnly={readOnly}
            invalid={!!(projectName && !projectId)}
            valid={!!(projectName && projectId)}
            value={project}
            onChange={handleProjectChange} />
        </div>
      )}
      {showTaxRateInput && (
        <div className={Css.input}>
          <FormLabel>{uiTexts.taxRate}</FormLabel>
          <SelectTaxRateInput
            disabled={disabledInput}
            readOnly={readOnly}
            invalid={!!(taxRate.name && !taxRate.id)}
            valid={!!(taxRate.name && taxRate.id)}
            value={taxRate}
            onChange={handleTaxRateChange} />
        </div>
      )}
    </div>
  );
};

export default React.memo(ExtraDataInputs);
