import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button, DropDown } from "nlib/ui";
import { DropDownContent, DropDownMenuItem } from "nlib/ui/DropDown";
import { checkIsBusinessUser } from "selectors/user";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import { useInViewport } from "react-in-viewport";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import MainApiActions from "actions/MainApiActions";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import TransactionsActions from "actions/TransactionsActions";
import UiActions from "actions/UiActions";
import Utils from "utils/Utils";
import classNames from "classnames";
import useAttachDocument from "hooks/useAttachDocument";

const {
  STATUSES: { TO_REVIEW, TO_REPORT, EXPORTED },
  ADVANCED_TRANSACTION_TYPES: { TRANSFER }
} = DataConstants;

const CAMERA = "camera";

const FILE_DROP_ALLOWED_TYPES = Object.values(Constants.DOCUMENT_FILE_TYPES)
  .reduce((aggregator, { extensions, mimeType }) => [...aggregator, ...extensions, mimeType], []).join(",");

const Attachment = (props) => {
  const {
    type,
    status,
    vendorId,
    advancedType,
    className,
    transactionId,
    documentId,
    documentPreview,
    refetchTransactions,
    ...restProps
  } = props;

  const dispatch = useDispatch();

  const rootRef = useRef();

  const simpleFileRef = useRef();

  const cameraFileRef = useRef();

  const businessUser = useSelector(checkIsBusinessUser);

  const { inViewport } = useInViewport(rootRef);

  const { uiTexts } = useSelector(getTextsData);

  const attachDocument = useAttachDocument({ id: transactionId, documentId, type });

  const [opened, setOpened] = useState(false);

  const [imageSrc, setImageSrc] = useState(null);

  const [imageLoaded, setImageLoaded] = useState(false);

  const toReviewStatus = status === TO_REVIEW;

  const disabledInput = (businessUser ? toReviewStatus : (status === TO_REPORT || status === EXPORTED))
    || advancedType === TRANSFER;

  const imageStyle = useMemo(() => {
    return { backgroundImage: `url(${imageSrc})` };
  }, [imageSrc]);

  const fetchData = useCallback(async() => {
    setImageLoaded(false);
    setImageSrc(null);

    if (!documentPreview) return null;

    const response = await dispatch(MainApiActions.fetchAttachmentUrl(documentPreview, false, true));

    setImageSrc(response);
    await Utils.preloadImages([response]);
    setImageLoaded(true);

    return response;
  }, [documentPreview, dispatch]);

  const handleAttachmentClick = useCallback(() => {
    if (Utils.checkIsNativeAndroid()) {
      setOpened(true);
    } else {
      simpleFileRef.current.click();
    }
  }, []);

  const handleMenuItemClick = useCallback((value) => {
    if (value === CAMERA) {
      cameraFileRef.current.click();
    } else {
      simpleFileRef.current.click();
    }
  }, []);

  const handleFileChange = useCallback(async(event) => {
    const [file] = event.target.files;

    event.target.value = "";

    dispatch(TransactionsActions.lockTransaction(transactionId));

    const result = await attachDocument(file);

    dispatch(TransactionsActions.unlockTransaction(transactionId));
    if (result) refetchTransactions();
  }, [attachDocument, dispatch, refetchTransactions, transactionId]);

  const handlePreviewClick = useCallback(async() => {
    if (!imageSrc) return;
    dispatch(UiActions.showModalImages([""]));
    try {
      await Utils.preloadImages([imageSrc]);
      dispatch(UiActions.showModalImages([imageSrc]));
    } catch (error) {
      const response = fetchData();

      if (response) {
        dispatch(UiActions.showModalImages([response]));
      }
    }
  }, [dispatch, imageSrc, fetchData]);

  useEffect(() => {
    if (inViewport && !imageSrc && documentPreview) {
      fetchData();
    }
  }, [inViewport, imageSrc, documentPreview, fetchData]);

  if (disabledInput) return null;

  return (
    <div
      ref={rootRef}
      className={classNames(Css.attachment, className)}
      {...restProps}>
      {documentPreview
        ? (
          <div className={Css.preview} onClick={handlePreviewClick}>
            {imageLoaded
              ? <div className={Css.image} style={imageStyle} />
              : <Icons.Spinner data-wait />}
          </div>
        )
        : (
          <>
            <DropDown opened={opened} setOpened={setOpened}>
              <Button outline onClick={handleAttachmentClick}>
                <Icons.Paperclip />
              </Button>
              <DropDownContent alignRight className={Css.dropdownContent}>
                <DropDownMenuItem
                  value={CAMERA}
                  className={Css.menuItem}
                  onClick={handleMenuItemClick}>
                  <Icons.Camera /><span>{uiTexts.takePhoto}</span>
                </DropDownMenuItem>
                <DropDownMenuItem
                  className={Css.menuItem}
                  onClick={handleMenuItemClick}>
                  <Icons.File /><span>{uiTexts.uploadFile}</span>
                </DropDownMenuItem>
              </DropDownContent>
            </DropDown>
            <input
              type="file"
              className={Css.hidden}
              ref={simpleFileRef}
              accept={FILE_DROP_ALLOWED_TYPES}
              onChange={handleFileChange} />
            <input
              accept="image/*"
              capture="environment"
              type="file"
              className={Css.hidden}
              ref={cameraFileRef}
              onChange={handleFileChange} />
          </>
        )}
    </div>
  );
};

export default React.memo(Attachment);
