import Css from "./style.module.scss";

import { TransactionsContext } from "mlib/pages/TransactionsPage";
import { getTextsData } from "selectors/texts";
import { getUserRole } from "selectors/user";
import { useDispatch, useSelector } from "react-redux";
import AttachedDocumentActions from "nlib/common/AttachedDocumentActions";
import Constants from "const/Constants";
import CustomDropArea from "nlib/common/CustomDropArea";
import DataConstants from "const/DataConstants";
import FileTypedIcon from "nlib/common/FileTypedIcon";
import MainApiActions from "actions/MainApiActions";
import Preloader from "nlib/common/Preloader";
import React, { useCallback, useContext, useState } from "react";
import TransactionsActions from "actions/TransactionsActions";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";
import classNames from "classnames";
import useAttachDocument from "hooks/useAttachDocument";

const {
  STATUSES: { TO_REVIEW, TO_REPORT, EXPORTED, EXCLUDED }
} = DataConstants;

const ACCEPT_FILE_TYPES = Object
  .values(Constants.DOCUMENT_FILE_TYPES)
  .reduce((aggregator, { extensions, mimeType }) => [...aggregator, ...extensions, mimeType], []);

const Document = ({ className, transaction }) => {
  const {
    id: transactionId,
    type: transactionType,
    documentId,
    status,
    documentAttachment
  } = transaction;

  const dispatch = useDispatch();

  const { refetchTransactions } = useContext(TransactionsContext);

  const { messages } = useSelector(getTextsData);

  const userRole = useSelector(getUserRole);

  const attachDocument = useAttachDocument({ id: transactionId, documentId, type: transactionType });

  const [loading, setLoading] = useState(false);

  const businessUser = UserRoles.checkIsBusiness(userRole);

  const toReviewStatus = status === TO_REVIEW;

  const disabledInput = businessUser ? toReviewStatus : [TO_REPORT, EXCLUDED, EXPORTED].includes(status);

  const handleSelectFile = useCallback(async([file]) => {
    if (!file) return;
    setLoading(true);
    dispatch(TransactionsActions.lockTransaction(transactionId));

    const result = await attachDocument(file);

    if (result) refetchTransactions();

    setLoading(false);
    dispatch(TransactionsActions.unlockTransaction(transactionId));
  }, [attachDocument, dispatch, refetchTransactions, transactionId]);

  const handleFileInfoClick = useCallback(async() => {
    setLoading(true);

    const link = await dispatch(MainApiActions.fetchAttachmentUrl(documentAttachment, true));

    if (link) {
      Utils.downloadContent(link);
    }

    setLoading(false);
  }, [documentAttachment, dispatch]);

  if (documentId) {
    const { originalName } = documentAttachment || {};

    return (
      <div className={classNames(Css.document, Css.attached, className)}>
        <div className={Css.fileInfo} disabled={loading} onClick={handleFileInfoClick}>
          <FileTypedIcon className={Css.icon} fileName={originalName} />
          <div className={Css.name}>{originalName}</div>
        </div>
        <AttachedDocumentActions
          disabled={loading}
          className={Css.buttons}
          transaction={transaction} />
      </div>
    );
  }

  return (
    <div className={classNames(Css.document, className)}>
      <CustomDropArea
        disabled={disabledInput}
        accept={ACCEPT_FILE_TYPES}
        title={messages.addDocumentInstead}
        fileTypesList={Object.keys(Constants.DOCUMENT_FILE_TYPES).join(", ")}
        onChange={handleSelectFile} />
      {loading && (
        <Preloader absolute className={Css.preloader} />
      )}
    </div>
  );
};

export default React.memo(Document);
