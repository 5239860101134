import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { checkIsBusinessUser } from "selectors/user";
import {
  checkSelectedBusinessRpaMode,
  getCurrentQuickBooksRealmId,
  getCurrentZohoOrganizationId,
  getSelectedBusinessClasses,
  getSelectedBusinessData
} from "selectors/businesses";
import { getLockedTransactions } from "selectors/transactions";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Attachment from "./lib/Attachment";
import Button from "nlib/ui/Button";
import Checkbox from "nlib/ui/Checkbox";
import ConfidenceAppend from "nlib/common/ConfidenceAppend";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import LastComment from "mlib/common/LastComment";
import React, { useCallback, useMemo } from "react";
import ReasonInput from "nlib/common/ReasonInput";
import SelectCategoryInput from "nlib/common/SelectCategoryInput";
import SelectContactInput from "nlib/common/SelectContactInput";
import SelectItemInput from "nlib/common/SelectItemInput";
import TransactionAmount from "nlib/common/TransactionAmount";
import Transactions from "utils/Transactions";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";

const {
  TRANSACTION_TYPES: { WITHDRAW },
  STATUSES: { NEED_REACTION, EXPORTED, TO_REPORT, TO_REVIEW },
  ADVANCED_TRANSACTION_TYPES: { TRANSFER, BILL_PAYMENT, RECEIVED_PAYMENT }
} = DataConstants;

const ListItem = (props) => {
  const {
    selectable,
    readyToProcess,
    readyToReview,
    closedBookDate,
    readyToApprove,
    transaction,
    transactionState = {},
    selected,
    localReasons,
    refetchTransactions,
    onSelect,
    onChange,
    onItemClick,
    onReasonBlur
  } = props;

  const {
    id: transactionId,
    documentId,
    status,
    amount,
    reason: originalReason,
    type,
    currency,
    usesItems,
    timestamp,
    description,
    lastComment,
    comments,
    amountTotal = null,
    documentAttachment,
    extraData,
    documentPreview,
    aiProcessing,
    lastExportErrors
  } = transaction;

  const {
    vendorId,
    reason,
    item,
    class: classValue,
    directCategorySelection,
    category = {},
    address = {},
    address: { name: payeeName = "" } = {}
  } = transactionState;

  const { uiTexts } = useSelector(getTextsData);

  const businessUser = useSelector(checkIsBusinessUser);

  const rpaMode = useSelector(checkSelectedBusinessRpaMode);

  const classes = useSelector(getSelectedBusinessClasses);

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const zohoBusiness = !!useSelector(getCurrentZohoOrganizationId);

  const transactionLocked = !!useSelector(getLockedTransactions)[transactionId];

  const {
    settings: {
      advancedDocumentsWorkflow,
      allowClientPayeeSelection,
      allowApproveWithoutPayee
    } = {}
  } = useSelector(getSelectedBusinessData);

  const advancedType = useMemo(() => Transactions.getTransactionAdvancedType({ type, extraData }), [type, extraData]);

  const needReaction = status === NEED_REACTION;

  const typeTransfer = advancedType === TRANSFER;

  const billPayment = advancedType === BILL_PAYMENT;

  const receivePayment = advancedType === RECEIVED_PAYMENT;

  const statusExported = status === EXPORTED && !quickBooksBusiness;

  const showCategoryInput = advancedType !== BILL_PAYMENT && advancedType !== RECEIVED_PAYMENT;

  const seeTheDocument = !!documentId && advancedDocumentsWorkflow;

  const disableInputs = transactionLocked || aiProcessing || (
    businessUser ? status !== NEED_REACTION : (statusExported || status === TO_REPORT)
  );

  const disabledCategoryInput = billPayment || receivePayment || disableInputs || seeTheDocument;

  const handleListItemClick = useCallback(() => {
    onItemClick(transactionId);
  }, [transactionId, onItemClick]);

  const handleCheckboxClick = useCallback((event) => {
    event.stopPropagation();
  }, []);

  const handleSelectedChange = useCallback(() => {
    onSelect(transactionId, !selected);
  }, [transactionId, selected, onSelect]);

  const handleReasonChange = useCallback((value) => {
    onChange(transactionId, { reason: value });
  }, [transactionId, onChange]);

  const handleCategoryChange = useCallback((value) => {
    onChange(transactionId, { category: value, tags: value.code ? [value.name] : [] });
  }, [transactionId, onChange]);

  const handleItemChange = useCallback((value) => {
    onChange(transactionId, {
      item: value,
      tags: value.id ? [value.name] : [],
      class: Utils.arrayFindById(classes, value.classId, classValue)
    });
  }, [onChange, transactionId, classes, classValue]);

  const handleContactChange = useCallback((value) => {
    onChange(transactionId, { address: value, vendorId: value.id });
  }, [transactionId, onChange]);

  const handleAttachmentClick = useCallback((event) => {
    event.stopPropagation();
  }, []);

  return (
    <>
      <div
        className={classNames(Css.listItem,
          [
            (status === TO_REVIEW) && Array.isArray(lastExportErrors)
              && !!lastExportErrors.length && Css.negativeRow,
            (readyToApprove || readyToProcess) && closedBookDate && Css.warningRow,
            (readyToReview || readyToProcess) && Css.highlightRow,
            readyToApprove && Css.positiveRow
          ])}>
        <div className={Css.header} onClick={handleListItemClick}>
          <Checkbox
            className={Css.checkBox}
            checked={selected}
            disabled={!selectable || transactionLocked}
            onClick={handleCheckboxClick}
            onChange={handleSelectedChange} />
          <div className={Css.timestamp}>
            {timestamp
              ? moment.utc(timestamp).format(Constants.DATETIME_FORMATS.DATE_TEXT)
              : Constants.EMPTY_PLACEHOLDER}
          </div>
          <div className={Css.amountWrap}>
            <TransactionAmount
              className={Css.amount}
              amount={amount}
              type={type}
              currency={currency} />
            {amountTotal !== null && (
              <div className={Css.amountTotal}>
                <span>&nbsp;/&nbsp;</span>
                <TransactionAmount
                  amount={amountTotal}
                  currency={currency} />
              </div>
            )}
          </div>
        </div>
        <div className={Css.body}>
          <div className={Css.inputs}>
            {!typeTransfer && (!businessUser || allowClientPayeeSelection) && (
              <div className={Css.block}>
                <ConfidenceAppend confidence={address?.confidence}>
                  <SelectContactInput
                    useWidget
                    value={address}
                    disabled={disableInputs}
                    active={!!(address.name && !vendorId)}
                    invalid={(!vendorId && (!documentId || !advancedDocumentsWorkflow) && !allowApproveWithoutPayee)}
                    valid={!!(address.name && vendorId)}
                    onlyVendors={type === WITHDRAW && zohoBusiness}
                    onlyCustomers={!!usesItems || (!type !== WITHDRAW && zohoBusiness)}
                    probablyVendor={type === WITHDRAW && !usesItems}
                    onChange={handleContactChange} />
                </ConfidenceAppend>
              </div>
            )}
            {selectable && (
              <div className={Css.block}>
                {(rpaMode ? needReaction : (businessUser && !directCategorySelection))
                  ? (
                    <ReasonInput
                      validate
                      value={reason}
                      status={status}
                      disabled={disableInputs}
                      originalReason={originalReason}
                      localReasons={localReasons}
                      onChange={handleReasonChange}
                      onBlur={onReasonBlur} />
                  )
                  : (usesItems
                    ? (
                      <ConfidenceAppend confidence={item?.confidence}>
                        <SelectItemInput
                          value={item}
                          invalid={!item?.id}
                          valid={!!item?.id}
                          disabled={disabledCategoryInput}
                          onChange={handleItemChange} />
                      </ConfidenceAppend>
                    )
                    : (showCategoryInput && (
                      <>
                        <ConfidenceAppend confidence={seeTheDocument ? null : category?.confidence}>
                          <SelectCategoryInput
                            entityPaymentType={type}
                            disabled={disabledCategoryInput}
                            placeholder={seeTheDocument ? uiTexts.seeTheDocument : undefined}
                            value={seeTheDocument ? undefined : category}
                            valid={!!category.code}
                            invalid={(!!category.name || !businessUser) && !category.code}
                            assetAccountsOnly={advancedType === TRANSFER}
                            onChange={handleCategoryChange} />
                        </ConfidenceAppend>
                      </>
                    )))}
              </div>
            )}
          </div>
          <div className={Css.content} onClick={handleListItemClick}>
            <div className={Css.wrapper}>
              {!businessUser && !category?.code && !transaction.category?.code && !!transaction.reason && (
                <div className={classNames(Css.row, Css.highlight)}>
                  <Icons.Chat />
                  <span>{`${uiTexts.reason}: ${transaction.reason}`}</span>
                </div>
              )}
              {(businessUser && !allowClientPayeeSelection) && (
                <div
                  className={classNames(Css.row, {
                    [Css.highlight]: !!(payeeName && !vendorId),
                    [Css.valid]: !!(payeeName && vendorId),
                    [Css.invalid]: !payeeName && !allowApproveWithoutPayee
                  })}>
                  {payeeName ? (vendorId ? <Icons.User /> : <Icons.UserPlus />) : <Icons.User />}
                  <span>{payeeName || uiTexts.noPayee}</span>
                </div>
              )}
              <div
                className={classNames(Css.row, { [Css.warning]: !description })}>
                {description ? <Icons.Info /> : <Icons.Warning />}
                <span>{description || `${uiTexts.noBankDescriptionOrMemo} :(`}</span>
              </div>
              {!!documentAttachment && (
                <div
                  className={classNames(Css.row, Css.highlight)}>
                  <Icons.File />
                  <span>{documentAttachment.originalName}</span>
                </div>
              )}
            </div>
            {!usesItems && advancedType !== TRANSFER && (
              <Attachment
                className={Css.attachment}
                disabled={transactionLocked || aiProcessing}
                type={transaction.type}
                advancedType={advancedType}
                status={status}
                vendorId={vendorId}
                transactionId={transactionId}
                documentId={documentId}
                documentPreview={documentPreview}
                refetchTransactions={refetchTransactions}
                onClick={handleAttachmentClick} />
            )}
            <Button outline>
              {selectable ? <Icons.PencilSimple /> : <Icons.CaretRight />}
            </Button>
          </div>
        </div>
        {!!lastComment && (
          <div className={Css.footer}>
            <div className={Css.content}>
              <LastComment
                transactionId={transactionId}
                hasUnread={!!comments.unread}
                lastComment={lastComment} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(ListItem);
