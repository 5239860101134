import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import EditModal from "./lib/EditModal";
import React, { useCallback, useState } from "react";
import Utils from "utils/Utils";
import classNames from "classnames";

const BulkActions = (props) => {
  const {
    localReasons,
    selectedTransactions,
    selectedTransactionsData,
    onReasonBlur,
    onCancel,
    onEdit
  } = props;

  const { messages } = useSelector(getTextsData);

  const [modalShown, setModalShown] = useState(false);

  const title = Utils.replaceTextVars(
    messages.editCountTransaction,
    { count: selectedTransactions.length }
  );

  const handleEditClick = useCallback(() => {
    setModalShown(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setModalShown(false);
  }, []);

  const handleModalSubmit = useCallback((data) => {
    const reason = data.reason.trim();

    onEdit({
      ...(data.vendorId ? {
        address: data.address,
        vendorId: data.vendorId
      } : null),
      ...(data.category.code ? {
        category: data.category,
        tags: data.tags,
        directCategorySelection: true
      } : null),
      ...(data.class.id ? { class: data.class } : null),
      ...(data.location.id ? { location: data.location } : null),
      ...(data.project.id ? { project: data.project } : null),
      ...(data.taxRate.id ? { taxRate: data.taxRate } : null),
      ...((reason && !data.category.code) ? { reason } : null)
    });
    setModalShown(false);
  }, [onEdit]);

  return (
    <>
      <div className={Css.bulkActions}>
        <div className={classNames(Css.container, CommonCss.mobileContainer)}>
          <div className={Css.text}>{title}</div>
          <div className={Css.buttons}>
            <Button outline onClick={onCancel}>
              <Icons.X />
            </Button>
            <Button primary onClick={handleEditClick}>
              <Icons.PencilSimple />
            </Button>
          </div>
        </div>
      </div>
      {modalShown && (
        <EditModal
          title={title}
          localReasons={localReasons}
          selectedTransactionsData={selectedTransactionsData}
          onReasonBlur={onReasonBlur}
          onClose={handleModalClose}
          onSubmit={handleModalSubmit} />
      )}
    </>
  );
};

export default React.memo(BulkActions);
