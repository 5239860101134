import Css from "./style.module.scss";

import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Counter from "./lib/Counter";
import React, { useCallback, useLayoutEffect } from "react";
import Tab from "nlib/ui/Tabs/lib/Tab";
import Tabs from "nlib/ui/Tabs";
import Utils from "utils/Utils";
import classNames from "classnames";
import useEnvVars from "hooks/useEnvVars";

const COMMON_TABS_COUNT = 2;

const touchDevice = Utils.checkIsTouchDevice();

const StatusFilter = (props) => {
  const {
    disabled,
    simplifyLayout,
    className,
    defaultValue = null,
    statusesList,
    stats,
    onClick
  } = props;

  const { uiTexts, messages } = useSelector(getTextsData);

  const [{ status = defaultValue }, setEnvVars] = useEnvVars();

  const handleTabChange = useCallback((value) => {
    setEnvVars({ status: value, page: null, editItem: null });
    if (onClick) onClick(value);
  }, [onClick, setEnvVars]);

  useLayoutEffect(() => {
    if (status && !statusesList.find(({ value }) => value === status)) {
      setEnvVars({ status: defaultValue });
    }
  });

  return (
    <div
      className={classNames(
        Css.statusFilter,
        statusesList.length > COMMON_TABS_COUNT && Css.toManyTabs,
        simplifyLayout && Css.simplifyLayout,
        className
      )}>
      <Tabs disabled={disabled} current={status} onChange={handleTabChange}>
        {statusesList.map((statusData, index) => {
          const { value, iconComponent: Icon, labelLangId, descriptionMessageId } = statusData;

          const label = uiTexts[labelLangId];

          const totalCount = value ? stats[value] : stats.total;

          return (
            <Tab
              key={value}
              value={value}
              className={Css.tab}
              data-tabindex={index}
              data-status={value}
              data-tooltip-delay="600"
              data-tooltip={touchDevice ? undefined : messages[descriptionMessageId]}>
              {Icon
                ? <Icon className={Css.icon} title={label} />
                : null}
              <span className={Css.label} title={label}>{label}</span>

              <Counter
                className={Css.counter}
                loading={disabled || totalCount === undefined}
                value={totalCount}
                status={value} />
              <Counter
                className={classNames(Css.counter, Css.mobile)}
                loading={disabled || totalCount === undefined}
                value={totalCount}
                status={value} />
            </Tab>
          );
        })}
      </Tabs>
    </div>
  );
};

export default React.memo(StatusFilter);
