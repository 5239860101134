import { checkIsBusinessUser } from "selectors/user";
import {
  checkSelectedBusinessHasWhoAsk,
  checkSelectedBusinessRpaMode
} from "selectors/businesses";
import { getActiveOrganization } from "selectors/organizations";
import { getTransactionsStats } from "selectors/transactions";
import { useSelector } from "react-redux";
import DataConstants from "const/DataConstants";
import React, { useMemo } from "react";
import StatusFilter from "nlib/common/StatusFilter";
import TransactionsStatuses from "nlib/pages/TransactionsPage/TransactionsStatuses";
import Utils from "utils/Utils";
import useEnvVars from "hooks/useEnvVars";

const { STATUSES: { NEED_REACTION, TO_REVIEW, IN_PROGRESS } } = DataConstants;

const BUSINESS_STATUSES = [NEED_REACTION, TO_REVIEW];

const TransactionsStatusFilter = (props) => {
  const [{ text }] = useEnvVars();

  const rpaMode = useSelector(checkSelectedBusinessRpaMode);

  const selectedBusinessHasWhoAsk = useSelector(checkSelectedBusinessHasWhoAsk);

  const businessUser = useSelector(checkIsBusinessUser);

  const transactionsStats = useSelector(getTransactionsStats);

  const { [NEED_REACTION]: needReactionCount } = transactionsStats;

  const { businessOrganization } = useSelector(getActiveOrganization);

  const searchByMongoId = !!text && Utils.checkIsMongoDbId(text.replace("activityId:", ""));

  const statusesList = useMemo(() => {
    const list = Object.values(
      TransactionsStatuses.getStatusData([
        undefined,
        businessOrganization,
        rpaMode && !selectedBusinessHasWhoAsk,
        businessUser
      ])
    );

    return list.filter(({ value }) => businessUser
      ? BUSINESS_STATUSES.includes(value)
      : ((value !== NEED_REACTION || rpaMode || selectedBusinessHasWhoAsk || needReactionCount))
        && (value !== IN_PROGRESS || (rpaMode && !Utils.checkIsTouchDevice())));
  }, [businessOrganization, businessUser, rpaMode, selectedBusinessHasWhoAsk, needReactionCount]);

  return (
    <StatusFilter
      {...props}
      defaultValue={!searchByMongoId && (businessUser ? NEED_REACTION : null)}
      statusesList={statusesList}
      stats={transactionsStats} />
  );
};

export default React.memo(TransactionsStatusFilter);
