import Css from "./style.module.scss";

import { checkIsBusinessUser } from "selectors/user";
import {
  checkSelectedBusinessRpaMode,
  getCurrentQuickBooksRealmId,
  getCurrentZohoOrganizationId,
  getSelectedBusinessClasses,
  getSelectedBusinessData,
  getSelectedBusinessLocations,
  getSelectedBusinessProjects,
  getSelectedBusinessTaxRates
} from "selectors/businesses";
import { getActiveOrganization } from "selectors/organizations";
import { getLockedTransactions } from "selectors/transactions";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import Actions from "./lib/Actions";
import Activity from "nlib/common/Activity";
import CategoryAndReasonInput from "mlib/common/CategoryAndReasonInput";
import CommentsActions from "actions/CommentsActions";
import ConfidenceAppend from "nlib/common/ConfidenceAppend";
import Countries from "const/Countries";
import DataConstants from "const/DataConstants";
import Document from "./lib/Document";
import EventsActions from "actions/EventsActions";
import FormLabel from "nlib/common/FormLabel";
import Preloader from "nlib/common/Preloader";
import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import ReasonInput from "nlib/common/ReasonInput";
import SelectCategoryInput from "nlib/common/SelectCategoryInput";
import SelectClassInput from "nlib/common/SelectClassInput";
import SelectContactInput from "nlib/common/SelectContactInput";
import SelectItemInput from "nlib/common/SelectItemInput";
import SelectLocationInput from "nlib/common/SelectLocationInput";
import SelectProjectInput from "nlib/common/SelectProjectInput";
import SelectTaxRateInput from "nlib/common/SelectTaxRateInput";
import SideBar, { SideBarContent, SideBarFooter, SideBarHeader } from "nlib/common/SideBar";
import TransactionInfo from "./lib/TransactionInfo";
import Transactions from "utils/Transactions";
import Utils from "utils/Utils";
import usePreventBodyScroll from "hooks/usePreventBodyScroll";

const {
  TRANSACTION_TYPES: { WITHDRAW },
  STATUSES: { NEED_REACTION, TO_REPORT, EXPORTED },
  ADVANCED_TRANSACTION_TYPES: { TRANSFER, BILL_PAYMENT, RECEIVED_PAYMENT }
} = DataConstants;

const ListItemDetails = (props) => {
  const {
    selected,
    selectable,
    readyToProcess,
    readyToReview,
    readyToApprove,
    localReasons,
    transactionId,
    transactionData,
    transactionState,
    refetchTransactions,
    onClose,
    onChange: onTransactionChange,
    onSelect: onSelectedChange,
    onReasonBlur
  } = props;

  const {
    type,
    status,
    usesItems,
    documentId,
    comments,
    extraData,
    aiProcessing
  } = transactionData || {};

  const {
    reason,
    vendorId,
    manualMode,
    address = {},
    category = {},
    item = {},
    class: classValue = {},
    location = {},
    project = {},
    taxRate = {}
  } = transactionState || {};

  usePreventBodyScroll();

  const dispatch = useDispatch();

  const { uiTexts } = useSelector(getTextsData);

  const zohoBusiness = !!useSelector(getCurrentZohoOrganizationId);

  const businessUser = useSelector(checkIsBusinessUser);

  const rpaMode = useSelector(checkSelectedBusinessRpaMode);

  const { countryCode } = useSelector(getActiveOrganization);

  const taxRates = useSelector(getSelectedBusinessTaxRates);

  const classes = useSelector(getSelectedBusinessClasses);

  const locations = useSelector(getSelectedBusinessLocations);

  const projects = useSelector(getSelectedBusinessProjects);

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const {
    settings: {
      advancedDocumentsWorkflow,
      allowClientPayeeSelection,
      allowClientClassSelection,
      allowClientLocationSelection,
      allowClientProjectSelection,
      allowClientTaxRateSelection,
      allowApproveWithoutPayee
    } = {}
  } = useSelector(getSelectedBusinessData);

  const transactionLocked = !!useSelector(getLockedTransactions)[transactionId];

  const [activityLoaded, setActivityLoaded] = useState(false);

  const advancedType = useMemo(() => Transactions.getTransactionAdvancedType({ type, extraData }), [type, extraData]);

  const withdrawTransaction = type === WITHDRAW;

  const usCountry = countryCode === Countries.US;

  const seeTheDocument = !!documentId && advancedDocumentsWorkflow;

  const statusExported = status === EXPORTED && !quickBooksBusiness;

  const statusNeedReaction = status === NEED_REACTION;

  const billPayment = advancedType === BILL_PAYMENT;

  const receivePayment = advancedType === RECEIVED_PAYMENT;

  const typeTransfer = advancedType === TRANSFER;

  const disableInputs = transactionLocked || aiProcessing || (
    businessUser ? status !== NEED_REACTION : (statusExported || status === TO_REPORT)
  );

  const showCategoryInput = !disableInputs && !billPayment && !receivePayment;

  const showExtraInput = !disableInputs && !typeTransfer;

  const disabledCategoryInput = billPayment || receivePayment || disableInputs || seeTheDocument;

  const onChange = useCallback((update) => {
    onTransactionChange(transactionId, update);
  }, [transactionId, onTransactionChange]);

  const handleContactChange = useCallback((value) => {
    onChange({ address: value, vendorId: value.id || "" });
  }, [onChange]);

  const handleReasonChange = useCallback((value) => {
    onChange({ reason: value });
  }, [onChange]);

  const handleCategoryChange = useCallback((value) => {
    onChange({ category: value, tags: value.code ? [value.name] : [] });
  }, [onChange]);

  const handleItemChange = useCallback((value) => {
    onChange({
      item: value,
      tags: value.id ? [value.name] : [],
      class: Utils.arrayFindById(classes, value.classId, classValue)
    });
  }, [onChange, classes, classValue]);

  const handleTaxRateChange = useCallback((value) => {
    onChange({ taxRate: value });
  }, [onChange]);

  const handleClassChange = useCallback((newValue) => {
    onChange({ class: newValue });
  }, [onChange]);

  const handleLocationChange = useCallback((newValue) => {
    onChange({ location: newValue });
  }, [onChange]);

  const handleProjectChange = useCallback((newValue) => {
    onChange({ project: newValue });
  }, [onChange]);

  const handleSelectedChange = useCallback((id, newValue) => {
    onSelectedChange(id, newValue);
    onClose();
  }, [onClose, onSelectedChange]);

  const handleManualModeSet = useCallback(() => {
    onChange({ manualMode: true });
  }, [onChange]);

  useLayoutEffect(() => {
    const { TRANSACTIONS } = DataConstants.COMMENT_TARGET_TYPES;

    Promise.all([
      dispatch(CommentsActions.fetchComments(TRANSACTIONS, transactionId, true)),
      dispatch(EventsActions.fetchEventsByRoute(`/${TRANSACTIONS}`, transactionId))
    ]).then(() => {
      setActivityLoaded(true);
    });
  }, [dispatch, transactionId]);

  if (!transactionState || !transactionData) {
    return (
      <SideBar className={Css.listItemDetails}>
        <SideBarHeader onCloseClick={onClose}>
          {uiTexts.transaction}
        </SideBarHeader>
        <Preloader />
      </SideBar>
    );
  }

  return (
    <SideBar className={Css.listItemDetails}>
      <SideBarHeader onCloseClick={onClose}>
        {uiTexts.transaction}
      </SideBarHeader>
      <SideBarContent>
        <div className={Css.row}>
          {activityLoaded
            ? (
              <>
                <div className={Css.col}>
                  <TransactionInfo
                    className={Css.transactionInfo}
                    transactionData={transactionData}
                    transactionState={transactionState} />
                  {!typeTransfer && (!businessUser || allowClientPayeeSelection) && (
                    <div className={Css.input}>
                      <FormLabel>{uiTexts.payee}</FormLabel>
                      <ConfidenceAppend confidence={address?.confidence}>
                        <SelectContactInput
                          useWidget
                          value={address}
                          disabled={disableInputs}
                          active={!!(address.name && !vendorId)}
                          invalid={(!vendorId && (!documentId || !advancedDocumentsWorkflow) && !allowApproveWithoutPayee)}
                          valid={!!(address.name && vendorId)}
                          onlyVendors={withdrawTransaction && zohoBusiness}
                          onlyCustomers={!!usesItems || (!withdrawTransaction && zohoBusiness)}
                          probablyVendor={withdrawTransaction && !usesItems}
                          onChange={handleContactChange} />
                      </ConfidenceAppend>
                    </div>
                  )}

                  {businessUser
                    ? (
                      <div className={Css.input}>
                        <FormLabel>{`${uiTexts.whatIsIt}?`}</FormLabel>
                        <CategoryAndReasonInput
                          disabled={disableInputs}
                          transactionState={transactionState}
                          transaction={transactionData}
                          localReasons={localReasons}
                          onChange={onChange}
                          onReasonBlur={onReasonBlur} />
                      </div>
                    )
                    : (rpaMode && statusNeedReaction && !manualMode && (
                      <div className={Css.input}>
                        <FormLabel>{`${uiTexts.whatIsIt}?`}</FormLabel>
                        <ReasonInput
                          validate
                          value={reason}
                          disabled={disableInputs}
                          localReasons={localReasons}
                          onChange={handleReasonChange}
                          onBlur={onReasonBlur} />
                      </div>
                    ))}
                  {(businessUser || !rpaMode || !statusNeedReaction || manualMode) && (
                    <>
                      {!businessUser && showCategoryInput && (
                        (usesItems
                          ? (
                            <div className={Css.input}>
                              <FormLabel>{uiTexts.item}</FormLabel>
                              <ConfidenceAppend confidence={item?.confidence}>
                                <SelectItemInput
                                  disabled={disabledCategoryInput}
                                  value={item}
                                  valid={!!item?.id}
                                  invalid={!item?.id}
                                  onChange={handleItemChange} />
                              </ConfidenceAppend>
                            </div>
                          )
                          : (
                            <div className={Css.input}>
                              <FormLabel>{uiTexts.category}</FormLabel>
                              <ConfidenceAppend confidence={category?.confidence}>
                                <SelectCategoryInput
                                  entityPaymentType={type}
                                  disabled={disabledCategoryInput}
                                  placeholder={seeTheDocument ? uiTexts.seeTheDocument : undefined}
                                  value={category}
                                  valid={!!category.code}
                                  invalid={(!!category.name || !businessUser) && !category.code}
                                  assetAccountsOnly={advancedType === TRANSFER}
                                  onChange={handleCategoryChange} />
                              </ConfidenceAppend>
                            </div>
                          )
                        )
                      )}
                      {showExtraInput && (
                        <>
                          {(!businessUser || allowClientClassSelection) && !!classes.length && (
                            <div className={Css.input}>
                              <FormLabel>{uiTexts.class}</FormLabel>
                              <ConfidenceAppend confidence={classValue?.confidence}>
                                <SelectClassInput
                                  disabled={seeTheDocument || transactionLocked}
                                  placeholder={seeTheDocument ? uiTexts.seeTheDocument : undefined}
                                  invalid={!!(classValue?.name && !classValue?.id)}
                                  valid={!!(classValue?.name && classValue?.id)}
                                  value={classValue}
                                  onChange={handleClassChange} />
                              </ConfidenceAppend>
                            </div>
                          )}
                          {(!businessUser || allowClientLocationSelection) && !!locations.length && (
                            <div className={Css.input}>
                              <FormLabel>{uiTexts.location}</FormLabel>
                              <ConfidenceAppend confidence={location?.confidence}>
                                <SelectLocationInput
                                  disabled={seeTheDocument || transactionLocked}
                                  placeholder={seeTheDocument ? uiTexts.seeTheDocument : undefined}
                                  invalid={!!(location?.name && !location?.id)}
                                  valid={!!(location?.name && location?.id)}
                                  value={location}
                                  onChange={handleLocationChange} />
                              </ConfidenceAppend>
                            </div>
                          )}
                          {(!businessUser || allowClientProjectSelection) && !!projects.length && (
                            <div className={Css.input}>
                              <FormLabel>
                                {[
                                  projects.some(({ customer }) => !customer) && uiTexts.project,
                                  projects.some(({ customer }) => customer) && uiTexts.customer
                                ].filter(Boolean).join(" / ")}
                              </FormLabel>
                              <ConfidenceAppend confidence={project?.confidence}>
                                <SelectProjectInput
                                  disabled={seeTheDocument || transactionLocked}
                                  placeholder={seeTheDocument ? uiTexts.seeTheDocument : undefined}
                                  invalid={!!(project?.name && !project?.id)}
                                  valid={!!(project?.name && project?.id)}
                                  value={project}
                                  onChange={handleProjectChange} />
                              </ConfidenceAppend>
                            </div>
                          )}
                          {(!businessUser || allowClientTaxRateSelection) && !usCountry && !!taxRates.length && (
                            <div className={Css.input}>
                              <FormLabel>{uiTexts.taxRate}</FormLabel>
                              <ConfidenceAppend confidence={taxRate?.confidence}>
                                <SelectTaxRateInput
                                  value={taxRate}
                                  disabled={seeTheDocument || transactionLocked}
                                  placeholder={seeTheDocument ? uiTexts.seeTheDocument : undefined}
                                  invalid={!!(taxRate?.name && !taxRate?.id)}
                                  valid={!!(taxRate?.name && taxRate?.id)}
                                  onChange={handleTaxRateChange} />
                              </ConfidenceAppend>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}

                  {!usesItems && advancedType !== TRANSFER && (
                    <>
                      <div className={Css.separator}>
                        {!documentId && <span>{uiTexts.or}</span>}
                      </div>
                      <Document
                        vendorId={vendorId}
                        disabled={transactionLocked}
                        transaction={transactionData}
                        className={Css.document} />
                    </>
                  )}
                </div>
                <div className={Css.col}>
                  <FormLabel className={Css.activity}>{uiTexts.activity}</FormLabel>
                  <Activity
                    mobile
                    dataLoadedInitial
                    itemId={transactionId}
                    type={DataConstants.COMMENT_TARGET_TYPES.TRANSACTIONS}
                    commentsCount={comments} />
                </div>
              </>
            )
            : <Preloader />}
        </div>
      </SideBarContent>
      <SideBarFooter>
        <Actions
          selected={selected}
          selectable={selectable}
          readyToProcess={readyToProcess}
          readyToApprove={readyToApprove}
          readyToReview={readyToReview}
          transaction={transactionData}
          transactionState={transactionState}
          refetchTableData={refetchTransactions}
          onClose={onClose}
          onSelectedChange={handleSelectedChange}
          onManualModeSet={handleManualModeSet} />
      </SideBarFooter>
    </SideBar>
  );
};

export default React.memo(ListItemDetails);
