import Css from "./style.module.scss";

import { checkIsBusinessUser } from "selectors/user";
import { getCurrentQuickBooksRealmId, getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import DataConstants from "const/DataConstants";
import React, { useCallback, useMemo } from "react";
import ReasonInput from "nlib/common/ReasonInput";
import SelectCategoryInput from "nlib/common/SelectCategoryInput";
import Switcher from "nlib/ui/Switcher";
import Transactions from "utils/Transactions";

const TYPE_REASON = 0;

const TYPE_CATEGORY = 1;

const {
  STATUSES: { NEED_REACTION, EXPORTED },
  ADVANCED_TRANSACTION_TYPES: { TRANSFER, BILL_PAYMENT, RECEIVED_PAYMENT, TO_REPORT }
} = DataConstants;

const CategoryAndReasonInput = (props) => {
  const {
    portal,
    disabled,
    readOnly,
    transaction,
    localReasons,
    transactionState,
    validate = true,
    documentId,
    onChange,
    onReasonBlur
  } = props;

  const { uiTexts } = useSelector(getTextsData);

  const selectedBusinessData = useSelector(getSelectedBusinessData);

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const businessUser = useSelector(checkIsBusinessUser);

  const {
    settings: {
      allowClientCategorySelection,
      advancedDocumentsWorkflow
    } = {}
  } = selectedBusinessData;

  const { reason: originalReason, usesItems, status, type, extraData } = transaction || {};

  const {
    reason,
    category = {},
    directCategorySelection
  } = transactionState;

  const advancedType = useMemo(() => Transactions.getTransactionAdvancedType({ type, extraData }), [type, extraData]);

  const selectOptions = useMemo(() => [
    { value: TYPE_REASON, label: uiTexts.reason },
    { value: TYPE_CATEGORY, label: uiTexts.category }
  ], [uiTexts]);

  const assetAccountsOnly = advancedType === TRANSFER;

  const seeTheDocument = !!documentId && advancedDocumentsWorkflow;

  const billPayment = advancedType === BILL_PAYMENT;

  const receivePayment = advancedType === RECEIVED_PAYMENT;

  const statusExported = status === EXPORTED && !quickBooksBusiness;

  const disableInputs = (businessUser && status !== NEED_REACTION) || statusExported || status === TO_REPORT;

  const showCategoryInput = !disableInputs && !statusExported && !usesItems && !billPayment && !receivePayment;

  const handleTypeChange = useCallback((value) => {
    const newValue = value === TYPE_CATEGORY;

    onChange({
      directCategorySelection: newValue,
      reason: newValue ? "" : reason,
      category: newValue ? category : {}
    });
  }, [category, reason, onChange]);

  const handleReasonChange = useCallback((value) => {
    onChange({ reason: value });
  }, [onChange]);

  const handleCategoryChange = useCallback((value) => {
    onChange({ category: value, tags: value.code ? [value.name] : [] });
  }, [onChange]);

  return (
    <div className={Css.categoryAndReasonInput}>
      {businessUser && allowClientCategorySelection && showCategoryInput && (
        <Switcher
          className={Css.switcher}
          value={directCategorySelection ? TYPE_CATEGORY : TYPE_REASON}
          options={selectOptions}
          onChange={handleTypeChange} />
      )}
      {(showCategoryInput && (directCategorySelection || !businessUser))
        ? (
          <SelectCategoryInput
            portal={portal}
            entityPaymentType={type}
            className={Css.input}
            disabled={disableInputs || seeTheDocument}
            placeholder={seeTheDocument ? uiTexts.seeTheDocument : undefined}
            readOnly={readOnly}
            value={category}
            valid={!!category.code}
            invalid={!category.code && (validate || !!category.name)}
            assetAccountsOnly={assetAccountsOnly}
            onChange={handleCategoryChange} />
        )
        : (
          <ReasonInput
            useCaret
            portal={portal}
            validate={validate}
            className={Css.input}
            disabled={disabled || disableInputs}
            readOnly={readOnly}
            value={(businessUser && status === NEED_REACTION ? reason : originalReason) || ""}
            originalReason={originalReason}
            localReasons={localReasons}
            onChange={handleReasonChange}
            onBlur={onReasonBlur} />
        )}
    </div>
  );
};

export default React.memo(CategoryAndReasonInput);
