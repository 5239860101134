import Css from "./style.module.scss";

import Badge from "nlib/ui/Badge";
import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";

const Counter = ({ loading, value, status, className }) => {
  const [animated, setAnimated] = useState(false);

  const prevPropsRef = useRef({});

  const handleAnimationEnd = useCallback(() => {
    setAnimated(false);
  }, []);

  useEffect(() => {
    const {
      value: prevValue = null
    } = prevPropsRef.current;

    if (value && (prevValue !== null) && (prevValue !== value)) {
      setAnimated(true);
    }

    prevPropsRef.current = { value };
  }, [value]);

  return (
    <Badge
      counter
      title={value}
      data-status={status}
      className={classNames(
        Css.counter,
        !!value && Css.withValue,
        !loading && animated && Css.animated,
        className
      )}
      onAnimationEnd={handleAnimationEnd}>
      {value}
    </Badge>
  );
};

export default React.memo(Counter);
