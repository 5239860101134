import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { checkIsBusinessUser } from "selectors/user";
import { getCurrentZohoOrganizationId, getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import CategoryAndReasonInput from "mlib/common/CategoryAndReasonInput";
import DataConstants from "const/DataConstants";
import ExtraDataInputs from "nlib/common/ExtraDataInputs";
import Modal from "nlib/ui/Modal";
import React, { useCallback, useMemo, useState } from "react";
import SelectContactInput from "nlib/common/SelectContactInput";

const { STATUSES: { NEED_REACTION }, TRANSACTION_TYPES: { WITHDRAW } } = DataConstants;

const getInitialState = () => ({
  address: { name: "" },
  category: {},
  reason: "",
  class: {},
  location: {},
  project: {},
  taxRate: {}
});

const EditModal = (props) => {
  const {
    title,
    selectedTransactionsData,
    localReasons,
    onClose,
    onReasonBlur,
    onSubmit
  } = props;

  const { uiTexts } = useSelector(getTextsData);

  const {
    settings: { allowClientPayeeSelection } = {}
  } = useSelector(getSelectedBusinessData);

  const zohoBusiness = !!useSelector(getCurrentZohoOrganizationId);

  const businessUser = useSelector(checkIsBusinessUser);

  const [data, setData] = useState(getInitialState);

  const transaction = useMemo(() => {
    return {
      usesItems: selectedTransactionsData.every((item) => item.usesItems),
      status: NEED_REACTION
    };
  }, [selectedTransactionsData]);

  const withdrawTransaction = data.type === WITHDRAW;

  const contactValid = !!(data.address.name && data.vendorId);

  const hasDataToSave = [
    data.vendorId,
    data.reason.trim(),
    data.category.code,
    data.class.id,
    data.location.id,
    data.project.id,
    data.taxRate.id
  ].some(Boolean);

  const updateData = useCallback((newData) => {
    setData((prev) => ({ ...prev, ...newData }));
  }, []);

  const handleContactChange = useCallback((value) => {
    updateData({ address: value, vendorId: value.id });
  }, [updateData]);

  const handleChange = useCallback((newData) => updateData(newData), [updateData]);

  const handleSaveClick = useCallback(() => {
    onSubmit(data);
  }, [data, onSubmit]);

  return (
    <Modal
      title={title}
      className={Css.editModal}
      iconComponent={Icons.PencilSimple}
      onClose={onClose}>
      {(!businessUser || allowClientPayeeSelection) && (
        <div className={Css.input}>
          <SelectContactInput
            useWidget
            onlyVendors={withdrawTransaction && zohoBusiness}
            onlyCustomers={!!data.usesItems || (!withdrawTransaction && zohoBusiness)}
            probablyVendor={withdrawTransaction && !data.usesItems}
            valid={contactValid}
            value={data.address}
            onChange={handleContactChange} />
        </div>
      )}
      <div className={Css.input}>
        <CategoryAndReasonInput
          transactionState={data}
          transaction={transaction}
          validate={false}
          localReasons={localReasons}
          onChange={handleChange}
          onReasonBlur={onReasonBlur} />
      </div>
      <div className={Css.input}>
        <ExtraDataInputs
          transactionState={data}
          transaction={transaction}
          onChange={handleChange} />
      </div>
      <div className={Css.buttons}>
        <Button outline onClick={onClose}>
          {uiTexts.cancel}
        </Button>
        <Button
          primary
          disabled={!hasDataToSave}
          onClick={handleSaveClick}>
          {uiTexts.save}
        </Button>
      </div>
    </Modal>
  );
};

export default React.memo(EditModal);
