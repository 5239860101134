import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { checkIsBusinessUser } from "selectors/user";
import { checkSelectedBusinessRpaMode, getCurrentZohoOrganizationId, getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import DataConstants from "const/DataConstants";
import ExtraDataInputs from "nlib/common/ExtraDataInputs";
import Modal from "nlib/ui/Modal";
import React, { useCallback, useMemo, useState } from "react";
import ReasonInput from "nlib/common/ReasonInput";
import SelectCategoryInput from "nlib/common/SelectCategoryInput";
import SelectContactInput from "nlib/common/SelectContactInput";

const {
  STATUSES: { NEED_REACTION, TO_REVIEW },
  TRANSACTION_TYPES: { WITHDRAW }
} = DataConstants;

const getInitialState = () => ({ address: { name: "" }, category: {}, reason: "", class: {}, location: {}, project: {} });

const EditModal = (props) => {
  const {
    title,
    selectedTransactionsData,
    localReasons,
    onClose,
    onReasonBlur,
    onSubmit
  } = props;

  const { uiTexts } = useSelector(getTextsData);

  const {
    settings: {
      allowClientPayeeSelection,
      allowClientCategorySelection
    } = {}
  } = useSelector(getSelectedBusinessData);

  const zohoBusiness = !!useSelector(getCurrentZohoOrganizationId);

  const businessUser = useSelector(checkIsBusinessUser);

  const rpaMode = useSelector(checkSelectedBusinessRpaMode);

  const selectedTransactionsUsesItems = useMemo(() => {
    return selectedTransactionsData.every(({ usesItems }) => usesItems);
  }, [selectedTransactionsData]);

  const showReason = useMemo(() => {
    return !rpaMode || selectedTransactionsData.some(({ status }) => status === NEED_REACTION);
  }, [rpaMode, selectedTransactionsData]);

  const showCategory = useMemo(() => {
    return !rpaMode || (!selectedTransactionsUsesItems && selectedTransactionsData.some(({ status }) => status === TO_REVIEW));
  }, [rpaMode, selectedTransactionsUsesItems, selectedTransactionsData]);

  const [data, setData] = useState(getInitialState);

  const transaction = useMemo(() => {
    return {
      usesItems: selectedTransactionsData.every((item) => item.usesItems),
      status: NEED_REACTION
    };
  }, [selectedTransactionsData]);

  const withdrawTransaction = data.type === WITHDRAW;

  const contactValid = !!(data.address.name && data.vendorId);

  const hasDataToSave = !!(
    data.vendorId || data.reason.trim() || data.category.code || data.class.id || data.location.id || data.project.id
  );

  const updateData = useCallback((newData) => {
    setData((prev) => ({ ...prev, ...newData }));
  }, []);

  const handleContactChange = useCallback((value) => {
    updateData({ address: value, vendorId: value.id });
  }, [updateData]);

  const handleReasonChange = useCallback((value) => {
    updateData({ reason: value });
  }, [updateData]);

  const handleCategoryChange = useCallback((value) => {
    updateData({ category: value, tags: value.code ? [value.name] : [] });
  }, [updateData]);

  const handleChange = useCallback((newData) => updateData(newData), [updateData]);

  const handleSaveClick = useCallback(() => {
    onSubmit(data);
  }, [data, onSubmit]);

  return (
    <Modal
      className={Css.editModal}
      title={title}
      iconComponent={Icons.PencilSimple}
      onClose={onClose}>
      {(!businessUser || allowClientPayeeSelection) && (
        <div className={Css.input}>
          <div className={Css.label}>{uiTexts.contact}</div>
          <SelectContactInput
            useWidget validate
            onlyVendors={withdrawTransaction && zohoBusiness}
            onlyCustomers={!!data.usesItems || (!withdrawTransaction && zohoBusiness)}
            probablyVendor={withdrawTransaction && !data.usesItems}
            valid={contactValid}
            value={data.address}
            onChange={handleContactChange} />
        </div>
      )}
      {showReason && (
        <div className={Css.input}>
          <div className={Css.label}>{uiTexts.reason}</div>
          <ReasonInput
            useCaret
            value={data.reason}
            localReasons={localReasons}
            onChange={handleReasonChange}
            onBlur={onReasonBlur} />
        </div>
      )}
      {showCategory && (
        <div className={Css.input}>
          <div className={Css.label}>{uiTexts.category}</div>
          <SelectCategoryInput
            validate
            className={Css.input}
            value={data.category}
            onChange={handleCategoryChange} />
        </div>
      )}
      {(!businessUser || allowClientCategorySelection) && (
        <div className={Css.input}>
          <ExtraDataInputs
            transactionState={data}
            transaction={transaction}
            onChange={handleChange} />
        </div>
      )}
      <div className={Css.buttons}>
        <Button outline onClick={onClose}>
          {uiTexts.cancel}
        </Button>
        <Button
          primary
          disabled={!hasDataToSave}
          onClick={handleSaveClick}>
          {uiTexts.save}
        </Button>
      </div>
    </Modal>
  );
};

export default React.memo(EditModal);
